import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Swal from "sweetalert2";
import Select from "react-select";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "sweetalert2/src/sweetalert2.scss";
import "../App.css";
import {
  checkPhoneclient,
  getToken,
  ExecApiDetailOrderWhatsap,
  insertTemporaryPedidos,
} from "./api";
import {
  customStyles,
  StyledInput,
  MessageAlertTokenExpire,
} from "../customStyles";
import { calculateCartTotal } from "./cartTotal";
Modal.setAppElement("#root");
const ProductMenu = ({ products, categories, modifier_groups }) => {

  const [isChecked, setIsChecked] = useState({});
  const [cart, setCart] = useState([]);
  const [acompanantesOptions, setAcompanantesOptions] = useState([]);
  const REDIRECT_WHATSAPP = process.env.REACT_APP_REDIRECT_WHATSAPP;
  const [modalIsOpen, setIsOpen] = useState(!1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAcompanantes, setSelectedAcompanantes] = useState({});
  const [selectedQuantities, setSelectedQuantities] = useState({});
  const [selectedQuantitiesV2, setSelectedQuantitiesV2] = useState({});


  //maneja el estado de alerta de la promocion para mostrase una sola vez
  const [promoShown, setPromoShown] = useState(false);
  const handleAcompananteChange = (itemId, acompananteId) => {
    setSelectedAcompanantes((prev) => ({
      ...prev,
      [itemId]: acompananteId,
    }));
  };
  const total = calculateCartTotal(cart, selectedQuantities, products);
  const [selections, setSelections] = useState({});
  const [allOptionsSelected, setAllOptionsSelected] = useState(!1);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const itemIdToCategoryName = {};
  categories.forEach((category) => {
    category.app_item_ids.forEach((itemId) => {
      itemIdToCategoryName[itemId] = category.category_name;
    });
  });
  const filteredProducts = products.filter(
    (product) =>
      (selectedCategory === "" ||
        itemIdToCategoryName[product.app_item_id] === selectedCategory) &&
      product.item_name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      product.is_sold_separately === !0
  );
  useEffect(() => {
    if (selectedProduct) {
      const modifierGroups = modifier_groups
        ? modifier_groups.filter((group) =>
            selectedProduct.app_modifier_group_ids.includes(
              group.app_modifier_group_id
            )
          )
        : [];
      const newAcompanantesOptions = modifierGroups.flatMap(
        (group) => group.app_mg_items
      );
      setAcompanantesOptions(newAcompanantesOptions);
    }
  }, [modifier_groups, selectedProduct]);
  const [isAddingToCart, setIsAddingToCart] = useState(!1);
  
  
  const addToCart = (productId, quantity) => {
    setIsAddingToCart(true);
    setTimeout(() => setIsAddingToCart(false), 1500);

    const existingProductIndex = cart.findIndex(
        (product) => product.app_item_id === productId
    );

    if (existingProductIndex >= 0) {
        const updatedCart = [...cart];
        updatedCart[existingProductIndex].quantity += quantity;
        setCart(updatedCart);
    } else {
        const selectedProduct = products.find(
            (product) => product.app_item_id === productId
        );

        const modifierGroup = modifier_groups
            ? modifier_groups.find((group) =>
                selectedProduct.app_modifier_group_ids.includes(
                    group.app_modifier_group_id
                )
            )
            : null;

        const newProductWithModifierGroup = {
            ...selectedProduct,
            quantity,
            acompanantes: obtenerAcompanantesParaProducto(
                selectedProduct,
                products,
                modifier_groups
            ),
            modifierGroupId: modifierGroup
                ? modifierGroup.app_modifier_group_id
                : null,
        };

        setCart((prevCart) => [...prevCart, newProductWithModifierGroup]);
        const newAcompanantesOptions = modifierGroup
            ? modifierGroup.app_mg_items
            : [];
        setAcompanantesOptions(newAcompanantesOptions);
    }

    const additionalProducts = products.filter(
        (product) => product.aplica_promo === true
    );

    if (additionalProducts.length > 0 && !promoShown) {
        Swal.fire({
            title: '🎉 ¡Felicidades! 🎉',
            text: '¡Haz sido seleccionado para una promoción especial de Kokoriko! Echa un vistazo en tu carrito y descubre los beneficios que hemos añadido para ti.',
            icon: 'success',
            confirmButtonText: 'Continuar',
        });
        setPromoShown(true);
    }

    additionalProducts.forEach((additionalProduct) => {
        const existingAdditionalProductIndex = cart.findIndex(
            (product) => product.app_item_id === additionalProduct.app_item_id
        );

        if (existingAdditionalProductIndex === -1) {
            const newAdditionalProduct = {
                ...additionalProduct,
                quantity: 1,
                acompanantes: obtenerAcompanantesParaProducto(
                    additionalProduct,
                    products,
                    modifier_groups
                ),
                modifierGroupId: null,
            };
            setCart((prevCart) => [...prevCart, newAdditionalProduct]);
        }
    });

    setShouldBlink(true);
    setTimeout(() => setShouldBlink(false), 2000);
};
  const obtenerAcompanantesParaProducto = (
    producto,
    items,
    modifier_groups
  ) => {
    if (!Array.isArray(modifier_groups)) {
      return [];
    }
    const gruposModificadores = modifier_groups.filter((group) =>
      producto.app_modifier_group_ids.includes(group.app_modifier_group_id)
    );
    if (!gruposModificadores.length) return [];
    let acompanantes = [];
    gruposModificadores.forEach((grupo) => {
      const acompanantesGrupo = items.filter((item) =>
        grupo.app_mg_items.some(
          (mg_item) => mg_item.app_item_id === item.app_item_id
        )
      );
      acompanantes = [...acompanantes, ...acompanantesGrupo];
    });
    return acompanantes;
  };



  let isQuantityValid = !0;
  const InitOrderTemporary = async () => {


    if (total <= 15000) {
      MessageValorTotal();
      return;
    }
    
    const requiredGroups = cart.filter((item) =>
      item.modifier_groups.some(
        (group) =>
          group.is_required === 1 &&
          !selections[
            `group-${item.app_item_id}-${group.app_modifier_group_id}`
          ]
      )
    );
    if (requiredGroups.length > 0) {
      setError(!0);
      return;
    }
    cart.forEach((item) => {
      item.modifier_groups.forEach((group) => {
        if (group.is_required === 3) {
          const idPrefix = `${item.app_item_id}-`;
          const selectedQuantitiesForItem = Object.entries(selectedQuantitiesV2)
            .filter(([key]) => key.startsWith(idPrefix))
            .reduce(
              (obj, [key, value]) => ({
                ...obj,
                [key]: value,
              }),
              {}
            );
          const totalQuantity = Object.values(selectedQuantitiesForItem).reduce(
            (total, quantity) => total + quantity,
            0
          );
          const isGroupValid = totalQuantity === group.quantity_max_permitted;
          if (!isGroupValid) {
            isQuantityValid = !1;
          }
          //console.log("isGroupValid:", isGroupValid);
        }
      });
    });
    if (!isQuantityValid) {
      setError(!0);
      //console.log("isQuantityValid:", isQuantityValid);
      return;
    }

//aca estaba antes el alert 28-10-2024
    MessageAlertLoadingOrder();
    
    try {
      const selectedProducts = cart.map((item) => ({
        app_item_id: item.app_item_id,
        name: item.item_name,
        price: item.price,
        quantity: item.quantity,
      }));
      const item_pedidos_ids = cart.map((item) => {
        let sub_item_list = [];
        if (Array.isArray(item.modifier_groups)) {
          item.modifier_groups.forEach((group) => {
            if (Array.isArray(group.app_mg_items)) {
              group.app_mg_items.forEach((acompanante) => {
                if (
                  selectedQuantities[
                    `${item.app_item_id}-${acompanante.app_item_id}`
                  ] > 0
                ) {
                  const acompananteNombre = item.acompanantes.find(
                    (a) => a.app_item_id === acompanante.app_item_id
                  );
                  sub_item_list.push({
                    app_item_id: acompanante.app_item_id,
                    name_modifier_groups: acompananteNombre
                      ? acompananteNombre.item_name
                      : "Acompañante no encontrado",
                    quantity:
                      selectedQuantities[
                        `${item.app_item_id}-${acompanante.app_item_id}`
                      ],
                    price: acompananteNombre.price,
                  });
                }
              });
            }
          });
        }
        if (selectedRadio) {
          Object.entries(selectedRadio).forEach(
            ([groupName, groupSelections]) => {
              Object.keys(groupSelections).forEach((uniqueId) => {
                const [productId, acompananteId] = uniqueId.split("-");
                if (productId === item.app_item_id) {
                  if (groupSelections[uniqueId]) {
                    const acompananteSeleccionado = item.acompanantes.find(
                      (a) => a.app_item_id === acompananteId
                    );
                    if (acompananteSeleccionado) {
                      sub_item_list.push({
                        app_item_id: acompananteId,
                        name_modifier_groups: acompananteSeleccionado.item_name,
                        price: acompananteSeleccionado.price,
                        quantity: 1,
                      });
                    }
                  }
                }
              });
            }
          );
        }
        return {
          app_item_id: item.app_item_id,
          quantity: item.quantity,
          sub_item_list: sub_item_list,
        };
      });
      const urlParams = new URLSearchParams(window.location.search);
      const tokenFromUrl = urlParams.get("token");
      await insertTemporaryPedidos(item_pedidos_ids, tokenFromUrl, total);
      const phone = await checkPhoneclient(tokenFromUrl);
      if (!phone) {
        MessageAlertTokenExpire();
      }

      MessageAlertCheckWhatsap();
      await ExecApiDetailOrderWhatsap(
        tokenFromUrl,
        selectedProducts,
        phone,
        item_pedidos_ids,
        total
      );
      
      closeModal();
      window.location.href = REDIRECT_WHATSAPP;
    } catch (error) {
      console.error("Error al procesar la solicitud:", error);
    }
  };

  const MessageValorTotal = () => {
    Swal.fire({
      icon: "warning",
      title: "Valor mínimo no alcanzado",
      text: "El valor del pedido debe ser mayor de $15.000",
      confirmButtonText: "Ok",
      confirmButtonColor: "#d33"
    });
  };


  const MessageAlertCheckWhatsap = () => {
    Swal.fire({
      icon: "success",
      title: "Revise la bandeja de mensajería de WhatsApp",
      html: '<div class="progress-bar-container"><div class="progress-bar"></div></div>',
      showConfirmButton: !0,
      allowOutsideClick: !1,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };


  const MessageAlertLoadingOrder = () => {
    Swal.fire({
        icon: "info",
        title: "Cargando el pedido...",
        html: '<div class="progress-bar-container"><div class="progress-bar"></div></div>',
        showConfirmButton: !0,
        allowOutsideClick: !1,
        didOpen: () => {
            Swal.showLoading();
        },
    });
};


  const removeFromCart = (productId) => {
    const updatedCart = cart.filter((item) => item.app_item_id !== productId);
    setCart(updatedCart);
  };
  const openModal = () => {
    setIsOpen(!0);
  };
  const closeModal = () => {
    setIsOpen(!1);
  };
  const formattedTotal = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(total);
  const [shouldBlink, setShouldBlink] = useState(!1);
  const customTheme = (theme) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 20,
    },
    colors: {
      ...theme.colors,
      primary: "red",
    },
  });
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 10;
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(filteredProducts.length / productsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }
  const [error, setError] = useState(!1);
  const [selectedRadio, setSelectedRadio] = useState([]);
  const handleCheckboxChange = (event, quantity_max_permitted, uniqueId) => {
    const selectedCount = Object.values(isChecked).filter(Boolean).length;
    if (
      quantity_max_permitted &&
      selectedCount >= quantity_max_permitted &&
      !isChecked[uniqueId]
    ) {
      return;
    }
    setIsChecked({
      ...isChecked,
      [uniqueId]: event.target.checked,
    });
    if (event.target.checked) {
      setSelectedQuantities({
        ...selectedQuantities,
        [uniqueId]: 1,
      });
    } else {
      setSelectedQuantities({
        ...selectedQuantities,
        [uniqueId]: 0,
      });
    }
    if (event.target.checked) {
      setTotalSelected(totalSelected + 1);
    } else {
      setTotalSelected(totalSelected - 1);
    }
  };
  const [totalSelected, setTotalSelected] = useState(0);
  const handleCheckboxChangev2 = (
    event,
    quantity_max_permitted,
    uniqueId,
    item
  ) => {
    const idPrefix = uniqueId.split("-")[0];
    const selectedCount = Object.entries(selectedQuantities)
      .filter(([key]) => key.startsWith(idPrefix))
      .reduce((total, [, value]) => total + value, 0);
    //console.log("selectedCount" + selectedCount);
    //console.log("uniqueId" + uniqueId);
    setIsChecked({
      ...isChecked,
      [uniqueId]: event.target.checked,
    });
    if (event.target.checked) {
      setSelectedQuantities((prevQuantities) => ({
        ...prevQuantities,
        [uniqueId]: (prevQuantities[uniqueId] || 0) + 1,
      }));
      setSelectedQuantitiesV2((prevQuantities) => ({
        ...prevQuantities,
        [uniqueId]: (prevQuantities[uniqueId] || 0) + 1,
      }));
    } else {
      setSelectedQuantities((prevQuantities) => {
        return {
          ...prevQuantities,
          [uniqueId]: 0,
        };
      });
      setSelectedQuantitiesV2((prevQuantities) => {
        return {
          ...prevQuantities,
          [uniqueId]: 0,
        };
      });
      setIsChecked((prevChecked) => {
        return {
          ...prevChecked,
          [uniqueId]: !1,
        };
      });
    }
    if (event.target.checked) {
      setTotalSelected(totalSelected + 1);
    } else {
      setTotalSelected(totalSelected - 1);
    }
    const requiredGroups = cart.filter((item) =>
      item.modifier_groups.some(
        (group) =>
          group.is_required === 1 && !selections[group.app_modifier_group_id]
      )
    );
    setError(requiredGroups.length > 0);
  };
  const incrementAdicionesV2 = (productId, quantity_max_permitted) => {
    const idPrefix = productId.split("-")[0];
    const totalQuantity = Object.entries(selectedQuantitiesV2)
      .filter(([key]) => key.startsWith(idPrefix))
      .reduce((total, [, value]) => total + value, 0);
    if (totalQuantity < quantity_max_permitted) {
      setSelectedQuantities((prevQuantities) => ({
        ...prevQuantities,
        [productId]: (prevQuantities[productId] || 0) + 1,
      }));
      setSelectedQuantitiesV2((prevQuantities) => ({
        ...prevQuantities,
        [productId]: (prevQuantities[productId] || 0) + 1,
      }));
    }
  };
  const decrementAdicionesV2 = (productId) => {
    setSelectedQuantities((prevQuantities) => {
      const currentQuantity = prevQuantities[productId] || 0;
      if (currentQuantity > 0) {
        const newQuantity = currentQuantity - 1;
        if (newQuantity === 0) {
          setIsChecked((prevChecked) => ({
            ...prevChecked,
            [productId]: !1,
          }));
        }
        return {
          ...prevQuantities,
          [productId]: newQuantity,
        };
      } else {
        return prevQuantities;
      }
    });
    setSelectedQuantitiesV2((prevQuantities) => {
      const currentQuantity = prevQuantities[productId] || 0;
      if (currentQuantity > 0) {
        const newQuantity = currentQuantity - 1;
        if (newQuantity === 0) {
          setIsChecked((prevChecked) => ({
            ...prevChecked,
            [productId]: !1,
          }));
        }
        return {
          ...prevQuantities,
          [productId]: newQuantity,
        };
      } else {
        return prevQuantities;
      }
    });
  };
  useEffect(() => {
    for (const [productId, quantity] of Object.entries(selectedQuantities)) {
      if (quantity === 0) {
        setIsChecked((prevChecked) => ({
          ...prevChecked,
          [productId]: !1,
        }));
      }
    }
  }, [selectedQuantities]);
  useEffect(() => {
    for (const [productId, quantity] of Object.entries(selectedQuantitiesV2)) {
      if (quantity === 0) {
        setIsChecked((prevChecked) => ({
          ...prevChecked,
          [productId]: !1,
        }));
      }
    }
  }, [selectedQuantitiesV2]);
  const incrementQuantity = (productId) => {
    setSelectedQuantities({
      ...selectedQuantities,
      [productId]: (selectedQuantities[productId] || 1) + 1,
    });
  };
  const decrementQuantity = (productId) => {
    const currentQuantity = selectedQuantities[productId] || 1;
    if (currentQuantity > 1) {
      setSelectedQuantities({
        ...selectedQuantities,
        [productId]: currentQuantity - 1,
      });
    }
  };
  const incrementAdiciones = (productId, quantity_max_permitted) => {
    const currentQuantity = selectedQuantities[productId] || 1;
    if (currentQuantity < quantity_max_permitted) {
      setSelectedQuantities({
        ...selectedQuantities,
        [productId]: currentQuantity + 1,
      });
    }
  };
  const decrementAdiciones = (productId) => {
    const currentQuantity = selectedQuantities[productId] || 0;
    if (currentQuantity > 1) {
      setSelectedQuantities({
        ...selectedQuantities,
        [productId]: currentQuantity - 1,
      });
    }
  };
  const [expanded, setExpanded] = useState(!1);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : !1);
  };
  const handleRadioChange = (item, group) => {
    setSelections((prevSelections) => {
      const groupName = `group-${item.app_item_id}-${group.app_modifier_group_id}`;
      if (
        !prevSelections[groupName] ||
        prevSelections[groupName] !== item.app_item_id
      ) {
        return {
          ...prevSelections,
          [groupName]: item.app_item_id,
        };
      }
      return prevSelections;
    });
  };
  const [areConditionsMet, setAreConditionsMet] = useState(!1);
  return (
    <React.Fragment>
      <Select
        options={[
          {
            value: "",
            label: "Todos los productos",
          },
          ...categories.map((category) => ({
            value: category.category_name,
            label: category.category_name,
          })),
        ]}
        value={selectedCategory}
        onChange={(option) => setSelectedCategory(option.value)}
        theme={customTheme}
        placeholder="Elige una categoría..."
      />
      {}
      {selectedCategory && (
        <div className="selected-category">
          <h2>Categoría seleccionada:{selectedCategory}</h2>
        </div>
      )}
      <div className="sidebar">
        <div className={`ex-fdlist ${isAddingToCart ? "cart-fly" : ""}`}>
          <div className="exfd-shopping-cart" onClick={openModal}>
            <img
              decoding="async"
              src="/carritokokoriko.svg"
              alt="imagen-cart"
            />
            <span className="exfd-cart-num">{cart.length}</span>
          </div>
        </div>
      </div>
      {}
      <div className="search-section">
        <FontAwesomeIcon icon={faSearch} className="search-icon" />
        <input
          type="text"
          placeholder="Consultar producto"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <br />
      <div className="product-menu">
        {}
        <div className="products-section">
          {currentProducts.map((product) => (
            <div key={product.app_item_id} className="product-item">
              <img
                src={`${product.head_img || ""}?${new Date().getTime()}`}
                alt={product.item_name}
                loading="lazy"
              />
              <h3>{product.item_name}</h3>
              <p>{product.short_desc}</p>
              <p>
                Precio:{" "}
                {new Intl.NumberFormat("es-CO", {
                  style: "currency",
                  currency: "COP",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(product.price)}
              </p>
              <button onClick={() => decrementQuantity(product.app_item_id)}>
                -
              </button>
              <StyledInput
                type="number"
                min="1"
                value={selectedQuantities[product.app_item_id] || 1}
                onChange={(e) =>
                  setSelectedQuantities({
                    ...selectedQuantities,
                    [product.app_item_id]: parseInt(e.target.value),
                  })
                }
              />
              <button onClick={() => incrementQuantity(product.app_item_id)}>
                +
              </button>
              <button
                className="add-to-cart"
                onClick={() =>
                  addToCart(
                    product.app_item_id,
                    selectedQuantities[product.app_item_id] || 1
                  )
                }
              >
                Añadir al carrito
              </button>
            </div>
          ))}
        </div>
        {}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Carrito de compras"
        >
          <button onClick={closeModal} className="button-volver">
            Volver al menú
          </button>
          <header className="modal-header">
            <img src="/img/header_koko.png" alt="Logo de Koko" />
          </header>
          {cart.map((item, itemIndex) => {
            return (
              <div key={item.app_item_id} className="cart-item">
                <h3>{item.item_name}</h3>
                <p>
                  Precio:{" "}
                  {new Intl.NumberFormat("es-CO", {
                    style: "currency",
                    currency: "COP",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(item.price)}
                </p>
                <p>Cantidad:{item.quantity}</p>
                <br />
                {Array.isArray(item.modifier_groups) &&
                  item.modifier_groups.map((group, groupIndex) => {
                    const panel = `panel${itemIndex}-${groupIndex}`;
                    return (
                      <Accordion
                        expanded={
                          expanded === panel ||
                          group.is_required === 1 ||
                          group.is_required === 3
                        }
                        onChange={handleChange(panel)}
                        key={group.app_modifier_group_id}
                        className="accordion"
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${itemIndex}-${groupIndex}bh-content`}
                          id={`panel${itemIndex}-${groupIndex}bh-header`}
                          className="accordion-summary"
                        >
                          <Typography
                            sx={{
                              width: "33%",
                              flexShrink: 0,
                            }}
                          >
                            {group.modifier_group_name}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="accordion-details">
                          {(() => {
                            if (group.is_required === 3) {
                              const idPrefix = `${item.app_item_id}-`;
                              const selectedQuantitiesForItem = Object.entries(
                                selectedQuantities
                              )
                                .filter(([key]) => key.startsWith(idPrefix))
                                .reduce(
                                  (obj, [key, value]) => ({
                                    ...obj,
                                    [key]: value,
                                  }),
                                  {}
                                );
                              const totalQuantity = Object.values(
                                selectedQuantitiesForItem
                              ).reduce(
                                (total, quantity) => total + quantity,
                                0
                              );
                              isQuantityValid =
                                totalQuantity == group.quantity_max_permitted;
                              //console.log(isQuantityValid);
                              if (!isQuantityValid) {
                                return (
                                  <div
                                    style={{
                                      color: "red",
                                    }}
                                  >
                                    Debes seleccionar la cantidad requerida de
                                    acompañamientos.
                                  </div>
                                );
                              }
                            }
                          })()}
                          {Array.isArray(group.app_mg_items) &&
                            group.app_mg_items.map((bebida) => {
                              const acompanante = item.acompanantes.find(
                                (a) => a.app_item_id === bebida.app_item_id
                              );
                              const product = products.find(
                                (product) =>
                                  product.app_item_id === bebida.app_item_id
                              );
                              const price = product ? product.price : 0;
                              return (
                                <div
                                  key={bebida.app_item_id}
                                  className="cart-opciones"
                                >
                                  {(() => {
                                    switch (group.is_required) {
                                      case 1:
                                        return (
                                          <>
                                            {error && (
                                              <div
                                                style={{
                                                  color: "red",
                                                }}
                                              >
                                                Por favor,selecciona una opción.
                                              </div>
                                            )}
                                            <input
                                              className="radio-button"
                                              type="radio"
                                              id={`${item.app_item_id}-${bebida.app_item_id}`}
                                              name={`group-${item.app_item_id}-${group.app_modifier_group_id}`}
                                              value={bebida.app_item_id}
                                              onChange={(e) => {
                                                handleRadioChange(item, group);
                                                setSelectedRadio(
                                                  (prevSelectedRadio) => {
                                                    const groupName = `group-${item.app_item_id}-${group.app_modifier_group_id}`;
                                                    const updatedGroupSelections =
                                                      {};
                                                    updatedGroupSelections[
                                                      `${item.app_item_id}-${e.target.value}`
                                                    ] = !0;
                                                    const updatedSelections = {
                                                      ...prevSelectedRadio,
                                                      [groupName]:
                                                        updatedGroupSelections,
                                                    };
                                                    return updatedSelections;
                                                  }
                                                );
                                              }}
                                            />
                                          </>
                                        );
                                      case 2:
                                        return (
                                          <>
                                            <input
                                              type="checkbox"
                                              id={`${item.app_item_id}-${bebida.app_item_id}`}
                                              name={group.app_modifier_group_id}
                                              value={bebida.app_item_id}
                                              onChange={(e) => {
                                                handleCheckboxChange(
                                                  e,
                                                  group.quantity_max_permitted,
                                                  `${item.app_item_id}-${bebida.app_item_id}`
                                                );
                                              }}
                                            />
                                            {isChecked[
                                              `${item.app_item_id}-${bebida.app_item_id}`
                                            ] && (
                                              <div>
                                                <button
                                                  className="button-size"
                                                  onClick={() =>
                                                    decrementAdiciones(
                                                      `${item.app_item_id}-${bebida.app_item_id}`
                                                    )
                                                  }
                                                >
                                                  -
                                                </button>
                                                <span>
                                                  {selectedQuantities[
                                                    `${item.app_item_id}-${bebida.app_item_id}`
                                                  ] || 1}
                                                </span>
                                                <button
                                                  className="button-size"
                                                  onClick={() =>
                                                    incrementAdiciones(
                                                      `${item.app_item_id}-${bebida.app_item_id}`,
                                                      group.quantity_max_permitted
                                                    )
                                                  }
                                                >
                                                  +
                                                </button>
                                              </div>
                                            )}
                                          </>
                                        );
                                      case 3:
                                        const idPrefix = `${item.app_item_id}-`;
                                        const selectedQuantitiesForItem =
                                          Object.entries(selectedQuantitiesV2)
                                            .filter(([key]) =>
                                              key.startsWith(idPrefix)
                                            )
                                            .reduce(
                                              (obj, [key, value]) => ({
                                                ...obj,
                                                [key]: value,
                                              }),
                                              {}
                                            );
                                        const totalQuantity = Object.values(
                                          selectedQuantitiesForItem
                                        ).reduce(
                                          (total, quantity) => total + quantity,
                                          0
                                        );
                                        isQuantityValid =
                                          totalQuantity ==
                                          group.quantity_max_permitted;
                                        //console.log("isQuantityValid:",isQuantityValid);
                                        const uniqueId = `${item.app_item_id}-${bebida.app_item_id}`;
                                        return (
                                          <>
                                            <input
                                              type="checkbox"
                                              id={uniqueId}
                                              name={group.app_modifier_group_id}
                                              value={bebida.app_item_id}
                                              onChange={(event) => {
                                                handleCheckboxChangev2(
                                                  event,
                                                  group.quantity_max_permitted,
                                                  uniqueId,
                                                  item
                                                );
                                              }}
                                              checked={
                                                isChecked[uniqueId] || !1
                                              }
                                              disabled={
                                                totalQuantity >=
                                                  group.quantity_max_permitted &&
                                                !isChecked[uniqueId]
                                              }
                                            />
                                            {isChecked[uniqueId] && (
                                              <div>
                                                <button
                                                  className="button-size"
                                                  onClick={() =>
                                                    decrementAdicionesV2(
                                                      uniqueId
                                                    )
                                                  }
                                                >
                                                  -
                                                </button>
                                                <span>
                                                  {selectedQuantities[
                                                    uniqueId
                                                  ] || 1}
                                                </span>
                                                <button
                                                  className="button-size"
                                                  onClick={() =>
                                                    incrementAdicionesV2(
                                                      uniqueId,
                                                      group.quantity_max_permitted
                                                    )
                                                  }
                                                >
                                                  +
                                                </button>
                                              </div>
                                            )}
                                          </>
                                        );
                                      default:
                                        return null;
                                    }
                                  })()}
                                  <label htmlFor={bebida.app_item_id}>
                                    {acompanante
                                      ? acompanante.item_name
                                      : bebida.app_item_id}
                                  </label>
                                  <p>
                                    Precio:{" "}
                                    {new Intl.NumberFormat("es-CO", {
                                      style: "currency",
                                      currency: "COP",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(product.price)}
                                  </p>
                                </div>
                              );
                            })}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                <br />
                <button
                  onClick={() => removeFromCart(item.app_item_id)}
                  aria-label="Quitar del carrito"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
                <br />
                <br />
                <br />
              </div>
            );
          })}
          {}
          <div className="total-cart">
            <p>Total:{formattedTotal}</p>
          </div>
          {error && (
            <div
              style={{
                color: "red",
              }}
            >
              Debe elegir una opción en los campos requeridos.
            </div>
          )}
          {cart.length > 0 && (
            <button className="checkout-btn" onClick={InitOrderTemporary}>
              <FontAwesomeIcon icon={faWhatsapp} />
              Iniciar pedido
            </button>
          )}
        </Modal>
      </div>
      <br />
      <div className="pagination">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Anterior
        </button>
        {pageNumbers.map((number) => (
          <button key={number} onClick={() => paginate(number)}>
            {number}
          </button>
        ))}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(filteredProducts.length / productsPerPage)
          }
        >
          Siguiente
        </button>
      </div>
    </React.Fragment>
  );
};
export default ProductMenu;
