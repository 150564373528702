import Swal from 'sweetalert2';
const API_URL_HEY_NOW = process.env.REACT_APP_HEY_LOGIN; 
const API_URL_TEMPORARY = process.env.REACT_APP_TEMPORARY_ODOO; 
const BASIC_AUTH_TOKEN = process.env.REACT_APP_BASIC_AUTH_TOKEN; 
const USUARIO_HEY = process.env.REACT_APP_USUARIO_HEY; 
const PASSWORD_HEY = process.env.REACT_APP_PASSWORD_HEY; 
const getToken = async () => {
    try {
        const response = await fetch(API_URL_HEY_NOW, {
            method: "POST",
            headers: {
                accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: USUARIO_HEY,
                password: PASSWORD_HEY,
            }),
        });
        const data = await response.json();
        return data.token;
    } catch (error) {
        console.error("Error al obtener el token:", error);
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Error al obtener el token',
        });
        return null;
    }
};

const ExecApiDetailOrderWhatsap = async (tokenFromUrl, selectedProducts, phone, item_pedidos_ids, total) => {
    const combinedProducts = selectedProducts.map((product) => {
        const productWithDetails = item_pedidos_ids.find((item) => item.app_item_id === product.app_item_id);
        return { ...product, sub_item_list: productWithDetails ? productWithDetails.sub_item_list : [] };
    });

    try {
        const APP_CORS = process.env.REACT_APP_CORS;
        const HEY_NOW_MESSAGE = process.env.REACT_APP_HEY_NOW_MESSAGE;
        const baseHeyMessage = `${HEY_NOW_MESSAGE}${phone}`;
        const fullUrl = `${APP_CORS}${baseHeyMessage}`;
        const domicilio = await checkAddressValue(tokenFromUrl);
        const formattedDomicilio = new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(domicilio);
        const formattedTotal = new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(total + domicilio);
        const formattedSubTotal = new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(total);
        let messageText = "🧾 Revisemos tu pedido:\n\n";
        messageText += "*Productos seleccionados:*\n\n";
        combinedProducts.forEach((product, index) => {
            const formattedPrice = new Intl.NumberFormat("es-CO", {
                style: "currency",
                currency: "COP",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(product.price * product.quantity);
            messageText += `🍗 *${product.quantity} ${product.name}*.\n`;
            messageText += `- _Precio: ${formattedPrice}_\n\n`;
            if (product.sub_item_list && product.sub_item_list.length > 0) {
                messageText += "*Acompañamientos:* \n";
                product.sub_item_list.forEach((subItem, subIndex) => {
                    const formattedAcompanante = new Intl.NumberFormat("es-CO", {
                        style: "currency",
                        currency: "COP",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(subItem.price * subItem.quantity);
                    messageText += `- ${subItem.quantity} ${subItem.name_modifier_groups}, -Precio: (${formattedAcompanante})\n`;
                });
            }
            messageText += "\n\n";
        });
        messageText += `-Valor domicilio: (${formattedDomicilio})_\n\n`;
        messageText += `> *Subtotal:* ${formattedSubTotal} 💸\n`;
        messageText += `> *Total a pagar:* ${formattedTotal} 💸\n`;
        const response = await fetch(fullUrl, {
            method: "POST",
            headers: {
                "partner-token": "fenalco$",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                partnerUser: {
                    id: 49,
                    names: "Alejandro",
                    lastNames: "Marin",
                },
                interactive: "REPLY_BUTTON",
                text: messageText,
                quick_replies: [{
                    title: "Finalizar pedido",
                    type: "postback",
                    payload: {
                        state: "validar_categoria_pago",
                    },
                }],
            }),
        });
        const data = await response.json();
    } catch (error) {
        console.error("Error al procesar la solicitud:", error);
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Error al procesar la solicitud en nuestro servicio de WhatsApp',
        });
    }
};

export const checkPhoneclient = async (tokenFromUrl) => {
    try {
        const TOKEN_ACTIVE = process.env.REACT_APP_ODOO_TOKEN_ACTIVE;
        const response = await fetch(TOKEN_ACTIVE, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: tokenFromUrl,
            }),
        });
        if (!response.ok) {
            throw new Error("Error en la solicitud HTTP");
        }
        const data = await response.json();
        if (data && data.result && data.result.token_active === !0) {
            return data.result.phone;
        } else {
            return !1;
        }
    } catch (error) {
        console.error("Error al procesar la solicitud con el telefono del cliente:", error);
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Error al procesar la solicitud con el telefono del cliente',
        });
        return !1;
    }
};

const checkTokenActive = async (tokenFromUrl) => {
    try {
        const TOKEN_ACTIVE = process.env.REACT_APP_ODOO_TOKEN_ACTIVE;
        const response = await fetch(TOKEN_ACTIVE, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: tokenFromUrl,
            }),
        });
        if (!response.ok) {
            throw new Error("Error en la solicitud HTTP");
        }
        const data = await response.json();
        if (data && data.result && data.result.token_active === !0) {
            return !0;
        } else {
            return !1;
        }
    } catch (error) {
        console.error("Error al procesar la solicitud con el token actual", error);
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Error al procesar la solicitud con el token actual',
        });
        return !1;
    }
};

const insertTemporaryPedidos = async (selectedProducts, tokenFromUrl, total) => {
    try {
        const response = await fetch(API_URL_TEMPORARY, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: tokenFromUrl,
                item_pedidos_ids: selectedProducts,
                valor_total: total,
            }),
        });
        const responseText = await response.text();
        console.log("Respuesta de la API:", responseText);
        try {
            const data = JSON.parse(responseText);
            return data;
        } catch (jsonError) {
            console.error("Error al analizar la respuesta JSON:", jsonError);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al analizar la respuesta JSON',
            });
            return null;
        }
    } catch (error) {
        console.error("Error al cargar los items del pedido:", error);
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Error al cargar los items del pedido, por favor intente luego',
        });
        return null;
    }
};

const checkAddressValue = async (tokenFromUrl) => {
    try {
        const URL_BASE = process.env.REACT_APP_API_URL_DOMICILIO;
        const urlWithToken = `${URL_BASE}${encodeURIComponent(tokenFromUrl)}`;
        const response = await fetch(urlWithToken, {
            method: "GET",
            headers: {
                Authorization: `Basic ${BASIC_AUTH_TOKEN}`,
            },
        });
        if (!response.ok) {
            throw new Error("Error en la solicitud HTTP");
        }
        const data = await response.json();
        if (data) {
            return data.valorDomicilio;
        } else {
            return 0;
        }
    } catch (error) {
        console.error("Error al procesar la solictud en la funcion checkAddressValue:", error);
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Error al procesar el valor de domicilio, por favor intente luego',
        });
        return !1;
    }
};

export {
    getToken,
    ExecApiDetailOrderWhatsap,
    checkTokenActive,
    insertTemporaryPedidos,
};