import React,{
    useState,useEffect
  }from "react";
  import "./App.css";
  import Swal from "sweetalert2";
  import ProductMenu from "./components/ProductMenu";
  import{
    checkTokenActive
  }from "./components/api";
  import{
    getItems,getCategories,getModifierGroups,
  }from "./components/ApiProducts";
  import{
    MessageAlertTokenExpire
  }from "./customStyles";
  const App=()=>{
    const[token,setToken]=useState("");
    const[showCatalog,setShowCatalog]=useState(!1);
    const[products,setProducts]=useState([]);
    const[categories,setCategories]=useState([]);
    const[modifier_groups,setModifierGroups]=useState([]);
    const urlParams=new URLSearchParams(window.location.search);
    const tokenFromUrl=urlParams.get("token");
    useEffect(()=>{
      const REDIREC_WHATSAPP_KOKO=process.env.REACT_APP_REDIREC_WHATSAPP_KOKO;
      if(tokenFromUrl){
        checkTokenActive(tokenFromUrl).then((active)=>{
          console.log("El token es: ",active);
          // if(active==!0)
          if(active==!0) {
            setToken(tokenFromUrl);
            setShowCatalog(!0)
          }else{
            MessageAlertTokenExpire()
          }
        })
      }
    },[]);
    useEffect(()=>{
      const fetchData=async()=>{
        try{
          const[items,categoriesData,modifierGroupsData]=await Promise.all([getItems(tokenFromUrl),getCategories(),getModifierGroups(),]);
          if(items)setProducts(items);
          if(categoriesData)setCategories(categoriesData);
          if(modifierGroupsData)setModifierGroups(modifierGroupsData);
          
        }catch(error){
          console.error("Error al obtener datos:",error)
        }
      };
      fetchData()
    },[]);
    useEffect(()=>{
      if(categories.length===0||products.length===0||modifier_groups.length===0){
        return
      }
  const categoryMap=new Map(categories.map((category)=>[category.app_item_ids,category.category_name,]));
      const modifierGroupMap=new Map(modifier_groups.map((group)=>[group.app_modifier_group_id,group]));
      const updatedProducts=products.map((product)=>{
        const categoryName=categoryMap.get(product.app_item_id);
        if(categoryName)product.category=categoryName;
        if(product.app_modifier_group_ids){
          product.modifier_groups=product.app_modifier_group_ids.map((id)=>modifierGroupMap.get(id)).filter(Boolean)
        }
  return product
      });
      if(updatedProducts.some((updatedProduct,index)=>updatedProduct!==products[index])){
        setProducts(updatedProducts)
      }
    },[categories,products,modifier_groups]);
    return(<div className="App"><header className="App-header"><img src="/img/header_koko.png" alt="Logo"/></header><main className="product-container">{
      showCatalog?(<ProductMenu
  products={
        products
      }
  categories={
        categories
      }
  modifier_groups={
        modifier_groups
      }/>):null
    }</main></div>)
  };
  export default App